



import { Vue, Component } from "vue-property-decorator";
import MainLayout from "@/common-app/views/MainLayout.vue";

@Component({
  components: {
    MainLayout
  }
})
export default class Layout extends Vue {}
